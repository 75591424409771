import React from "react";
import AudioCard from '../components/AudioCard/AudioCard';
import useAudio from '../hooks/useAudio';
import AudioText from "../components/AudioCard/AudioText";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

import '../Audio/Audio.scss';

const textVariants = {
    initial: {
        x: -500,
        opacity: 0,

    },
    animate:{
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: .1,
        },
    },
};

const titleTextVariants = {
    initial: {
        x: -500,
        opacity: 0,

    },
    animate:{
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: .1,
        },
    },
};

const cardsVariants = {
    initial: {
        opacity: .1,
        
        
    },
    animate:{
        opacity:1,
        
        transition: {
            duration: 2,
            staggerChildren: .1,
            delay: 1,
        }
        
    },
}

function Audio() {

    const audioData = useAudio()
    const ref = useRef()
    const isInView = useInView(ref, {margin:'-100px'})

    return(
        <div className="audio">
        <div className="audio__title">
            <motion.h1
                className="audio__title-text"
                variants={titleTextVariants}
                initial="initial"
                ref={ref}
                animate={isInView && "animate"}
            >
                My audio dictionaries
            </motion.h1>
        </div>
        <motion.div
            className="audio__title-text"
            variants={textVariants}
            initial="initial"
            ref={ref}
            animate={isInView && "animate"}
        >
            <AudioText/>        
        </motion.div>
        <motion.div
         className="audio__content"
         variants={cardsVariants}
         initial="initial"
         ref={ref}
         animate={isInView && "animate"}
         >
            <div className="audio__content-cards">
                <div className="audio__content-card"></div>
                {audioData.map(item =>(
                     <AudioCard data={item}/>

                ))}
                


                </div>
            </motion.div>
    </div>

    )
}

export default Audio;