import React from 'react';
import { BiLogoFacebookSquare, BiLogoInstagramAlt, BiLogoYoutube, BiLogoTelegram, BiLogoPatreon } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import { BiSolidUserCircle } from 'react-icons/bi';
import Sidebar from '../Sidebar/Sidebar';
import Profile from '../Profile/Profile';

import '../Navbar/Navbar.scss';


function Navbar() {


    return(
        <div className="navbar">
            {/* Sidebar */}
            <Sidebar/>
            <div className="wrapper">
                <motion.span 
                initial={{
                    opacity: 0,
                    scale: .5
                }}
                animate={{
                    opacity: 1,
                    scale: 1
                }}
                transition={{
                    duration: .5
                }}
                ><Link id='name' to="/#Home" style={{color:'#fff'}}>Ruslan Verin</Link>
                 <div>
                    <Link to="/Profile"><BiSolidUserCircle className='profile-icon' /></Link>
                </div></motion.span>
               
                <div className="social">
                    <a href="https://facebook.com/ruslanverin" target="_blank"><BiLogoFacebookSquare className='logo'/></a>
                    <a href="https://instagram.com/ruslanverin" target="_blank"><BiLogoInstagramAlt className='logo'/></a>
                    <a href="https://www.youtube.com/channel/UCNttYYf1q2RVWkc0Rhulmdw" target="_blank"><BiLogoYoutube className='logo'/></a>
                    <a href="https://t.me/ruslanverin" target="_blank"><BiLogoTelegram className='logo'/></a>
                    <a href="https://patreon.com/RuslanVerin" target="_blank"><BiLogoPatreon className='logo'/></a>
                </div>
            </div>
        </div>
    )
}

export default Navbar;