import React from 'react';
import { BiLogoFacebookSquare, BiLogoInstagramAlt, BiLogoYoutube, BiLogoTelegram, BiLogoPatreon, BiLogOut } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';

import SidebarP from '../Profile/SidebarP';
import '../Profile/NavbarProf.scss';

import '../Navbar/Navbar.scss';


function Navbar() {

    const handlerClick = () => {
        localStorage.clear();
    };

    return(
        <div className="navbar">
            
            <SidebarP/>
            <div className="wrapper">
                <motion.span
                initial={{
                    opacity: 0,
                    scale: .5
                }}
                animate={{
                    opacity: 1,
                    scale: 1
                }}
                transition={{
                    duration: .5
                }}
                >
                <Link className='name' to="/#Home" style={{color:'#fff'}}>Ruslan Verin</Link>
                <Link onClick={() => handlerClick()} style={{display:'flex', alignItems:"center", justifyContent:'center', gap:'5px', color:"#fff"}} to="/#Home">
                    <BiLogOut onClick={() => handlerClick()} style={{width:'20px', height:'20px', color:"#fff", cursor:'pointer'}}/>
                    Logout
                </Link>
                </motion.span>
                <div className="social">
                    <a href="https://facebook.com/ruslanverin" target="_blank"><BiLogoFacebookSquare className='logo'/></a>
                    <a href="https://instagram.com/ruslanverin" target="_blank"><BiLogoInstagramAlt className='logo'/></a>
                    <a href="https://www.youtube.com/channel/UCNttYYf1q2RVWkc0Rhulmdw" target="_blank"><BiLogoYoutube className='logo'/></a>
                    <a href="https://t.me/ruslanverin" target="_blank"><BiLogoTelegram className='logo'/></a>
                    <a href="https://patreon.com/RuslanVerin" target="_blank"><BiLogoPatreon className='logo'/></a>
                </div>
            </div>
        </div>
    )
}

export default Navbar;