import { motion } from "framer-motion";
import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import '../Profile/SidebarP.scss';
import { BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';

function SidebarP() {
     
    return(
        <motion.div className="arrow">
            <Link to="/#Home"><BiArrowBack style={{color:'#ed7505', width:'30px', height:'30px'}}/></Link> 
        </motion.div>
   
    )

};

export default SidebarP;