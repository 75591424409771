import  profile  from '../img/profile.png';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {AiOutlineClose} from 'react-icons/ai'
import Login from '../Login/Login';
import Register from '../components/Register/Register';
import ReactModal from 'react-modal';
import './Profile.scss';
import NavbarP from '../Profile/NavbarP';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import useProfile from '../hooks/useProfile';
import useHistory from '../hooks/useHistory'
import VideoCard from '../components/VideoCard/VideoCard';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function Profile() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [content, setContent] = useState(false);
  const ref= useRef();
  const {t} = useTranslation();
  

  const goToRegister = () => {
    setIsModalOpen(false);
    setRegisterModal(true)
  }

  const closeModal = () => {
    setIsModalOpen(false);
    if (!token) {
      navigate('/');
    }

  };

  const closeAfterLogin = (e) => {
    setIsModalOpen(false);
    window.location.reload();
};

  const closeRegister = () => {
    setRegisterModal(false)
    if (!token) {
      navigate('/');
    }
}

const goToForgotPassword = () => {
  setIsModalOpen(false);
  setForgotModal(true);
}

const closeForgot = () => {
  setForgotModal(false)
  if (!token) {
    navigate('/');
  }
}

  useEffect(() => {
    if (!token) {
      setIsModalOpen(true)
    }
  }, [token, navigate]);

  const data= useProfile();
  const historyData = useHistory();


  const FullVariants = {
    initial: {
      y: -500,
      opacity: 0,
  },
  animate:{
      y: 0,
      opacity: 1,
      transition: {
          duration: 1.5,
          staggerChildren: .1,
      },
  },
  };

  const ButtonVariants1 = {
    initial: {
      x: 500,
      opacity: 0,
  },
  animate:{
      x: 0,
      opacity: 1,
      transition: {
          duration: 2,
          staggerChildren: .1,
      },
  },
  };

  const ButtonVariants2 = {
    initial: {
      x: -500,
      opacity: 0,
  },
  animate:{
      x: 0,
      opacity: 1,
      transition: {
          duration: 2,
          staggerChildren: .1,
      },
  },
  };

  const TableVariants = {
    initial: {
      x: 300,
      opacity: 0,
  },
  animate:{
      x: 0,
      opacity: 1,
      transition: {
          duration: 1,
          
      },
  },
  };

  const CardVariants = {
    initial: {
      x: -300,
      opacity: 0,
  },
  animate:{
      x: 0,
      opacity: 1,
      transition: {
          duration: 1,
          
      },
  },
  };
  
  return (

    
    <motion.div
    variants={FullVariants}
    initial="initial"
    animate="animate"
    >
        
        <div className='profile'>
          <NavbarP/>
            <div className='profile__card'>
              <img src={profile} alt="profile"/>
              <h2>{data[0]?.user?.username}</h2>
              <p className='lastname'>{data[0]?.user?.first_name} {data[0]?.user?.last_name}</p>
              <p className='email'>{data[0]?.user?.email}</p>
            </div>
            <div className='buttons'>
                <motion.button 
                className='orders-btn'
                onClick={() => setContent(false)}
                variants={ButtonVariants1}
                initial="initial"
                animate="animate"
                >
                 {t('myOrdersBtn')} 
                </motion.button>
                <motion.button
                className='table-btn' 
                onClick={() => setContent(true)}
                variants={ButtonVariants2}
                initial="initial"
                animate="animate"
                >
                  {t('myHistotyOrdersBtn')}
                </motion.button>
            </div>
            {content === false && (
        <motion.div 
        className='orders'
        variants={CardVariants}
        initial="initial"
        ref={ref}
        animate="animate"
        style={{zIndex:99}}
        >
            <div className="orders__content-cards">
              <div className="orders__content-card"></div>
              {data[0]?.purchased_seasons.map(item =>(
                 <VideoCard data={item}/>

               ))}
            </div>
        </motion.div>
        )}
            {content=== true && (
              <motion.div
              className='historyTable'
              variants={TableVariants}
              initial="initial"
              ref={ref}
              animate="animate"
              style={{zIndex:99}}
              >
              <table class="table table-dark" style={{color:'white'}}>
                <thead>
                  <tr>
                    <th className='title' scope="col">#</th>
                    <th className='title'  scope="col">{t('tableCard')}</th>
                    <th className='title'  scope="col">{t('tableDate')}</th>
                    <th className='title'  scope="col">{t('tablePrice')}</th>
                  </tr>
                </thead>
                <tbody>
                {historyData.map((item, index) => (
                  <tr key={item.id}>
                  <th scope="row">{index + 1}</th>
                  <td className="card">{item.season}</td>
                  <td className="data">{item.date.replace(/-/g, '.')}</td>
                  <td className="price">₴{item.price}</td>
                </tr>
                ))}
                </tbody>
              </table>
            </motion.div>
            ) }

        </div>
        <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Login Modal"
        className="login-modal"
      >
        <button onClick={closeModal} className='closeModal-btn'><AiOutlineClose className='close-icon'/></button>
        <Login closeModal={closeAfterLogin} goToRegister={goToRegister} goToForgotPassword={goToForgotPassword}/>
      </ReactModal>
      <ReactModal
        isOpen={registerModal}
        onRequestClose={closeRegister}
        contentLabel="Register Modal"
        className="register-modal"
      >
         <button onClick={closeRegister} className='closeModal-btn'><AiOutlineClose className='close-icon'/></button>
         <Register closeRegister={closeRegister}/>
                    
      </ReactModal>

      <ReactModal
                isOpen={forgotModal}
                onRequestClose={closeForgot}
                contentLabel="Forgot Passworg Modal"
                className="login-modal"
                >
                    <button onClick={closeForgot} className='closeModal-btn'><AiOutlineClose className='close-icon'/></button>
                    <ForgotPassword closeForgotModal={closeForgot}/>
                </ReactModal>
    </motion.div>
  );
}

export default Profile;