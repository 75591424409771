import React from "react";


function Error() {
    return(
        <>
            <h1>404</h1>
        </>
    );
}

export default Error;