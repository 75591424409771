import React from "react";
import "./Links.scss";

const Links = ({ handleLinkClick }) => {
    const items = [
        "Home",
        "Dream",
        "Video",
        "Audio",
    ];

    const handleClick = (item) => {
        handleLinkClick(item); 

    };

    return (
        <div className="links">
            {items.map((item) => (
                <a 
                    href={`#${item}`}
                    key={item}
                    onClick={() => handleClick(item)}
                >
                    {item}
                </a>
            ))}
            <a className="sup" href="https://t.me/RuslanVerin_adv" target="_blank">
                Support
            </a>
        </div>
    );
};

export default Links;