import { useTranslation } from 'react-i18next';
import "../VideoCard/VideoText.scss";

function VideoText() {

    const {t} = useTranslation();

    return(
        <div className="videoText">
            <h2>
            {t('myBlosgText')}
            </h2>
        </div>
    )
}

export default VideoText;