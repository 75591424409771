import '../ImageContent/ImageContent.scss';
import travel1 from '../img/travel1.jpg';
import travel2 from '../img/travel2.jpg';
import travel3 from '../img/travel3.jpg';
import travel4 from '../img/travel4.jpg';
import travel5 from '../img/travel7.jpg';

import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';



const textVariants = {
    initial: {
        y: 500,
        opacity: 0,
    },
    animate:{
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: .1,
        },
    },
};

const imageVariants = {
    initial: {
        opacity: 0,
    },
    animate:{
        opacity:1,
        transition: {
            duration: 1,
            staggerChildren: .1,
            delay: 1,
        }
        
    },
}

const imageVariants2 = {
    initial: {
        opacity: 0,
    },
    animate:{
        opacity:1,
        transition: {
            duration: 1,
            staggerChildren: .1,
            delay: 1.5,
        }
        
    },
}

const imageVariants3 = {
    initial: {
        opacity: 0,
    },
    animate:{
        opacity:1,
        transition: {
            duration: 1,
            staggerChildren: .1,
            delay: 2,
        }
        
    },
}

const imageVariants4 = {
    initial: {
        opacity: 0,
    },
    animate:{
        opacity:1,
        transition: {
            duration: 1,
            staggerChildren: .1,
            delay: 2.5,
        }
        
    },
}

const imageVariants5 = {
    initial: {
        opacity: 0,
    },
    animate:{
        opacity:1,
        transition: {
            duration: 1,
            staggerChildren: .1,
            delay: 3,
        }
        
    },
}


function ImageContent() {

    const ref = useRef()
    const isInView = useInView(ref, {margin:'-100px'})
    return(
        <div className="imageContent">
            <motion.h2 
            className='title'
            variants={textVariants}
            initial="initial"
            // animate="animate"
            ref={ref}
            animate={isInView && "animate"}
            >
                take a trip to dream
            </motion.h2>
            <div className="img-container">
                <motion.div
                className="half1"
                variants={textVariants}
                initial="initial"
                ref={ref}
                animate={isInView && "animate"}
                >
                    <motion.img
                    className='travel1'
                    src={travel1}
                    variants={imageVariants}
                    alt="ghost1"
                    initial="initial"
                    ref={ref}
                    animate={isInView && "animate"}
                    style={{width: '300px', height: '300px', margin: '15px'}}/>   
                    <motion.img 
                    className='travel2'
                    src={travel2} 
                    variants={imageVariants2}
                    alt="ghost2"
                    initial="initial"
                    ref={ref}
                    animate={isInView && "animate"}
                    style={{width: '350px', height: '300px',marginLeft: '30px'}}/>
                        <div className="paris">
                            <motion.img
                             className='travel4'
                            src={travel4} 
                            variants={imageVariants3}
                            alt="paris"
                            initial="initial"
                            ref={ref}
                            animate={isInView && "animate"}
                            style={{width: '650px', height: '350px', padding:'15px 15px' }}/> 
                        </div>   
                </motion.div>
                <motion.div
                className="half2"
                variants={textVariants}
                initial="initial"
                ref={ref}
                animate={isInView && "animate"}
                
                >
                    <motion.img
                    className='travel3'
                    src={travel3} 
                    variants={imageVariants4}
                    alt="parisss"
                    initial="initial"
                    ref={ref}
                    animate={isInView && "animate"}
                    style={{width: '500px', height: '300px',padding:'10px 15px'}}/>  
                    <motion.img
                     className='travel5'
                    src={travel5} 
                    variants={imageVariants5}
                    alt="ghost3"
                    initial="initial"
                    ref={ref}
                    animate={isInView && "animate"}
                    style={{width: '450px', height: '300px'}}/>   
                </motion.div>
            </div> 
        </div>
    )
}

export default ImageContent;