import axios from "axios";

const { useState, useEffect } = require("react");

function useAudio(){
    const [data, setData] = useState([])

    useEffect(()=> {
        axios.get('https://admin.ruslanverin.com:8443/audio-diarys/')
        .then(res => {
            setData(res.data);
        }).catch(error =>{
            console.log(error)
        });
      },[])

      return data
}

export default useAudio;