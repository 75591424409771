import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Hero from "../Hero/Hero";
import ImageContent from "../ImageContent/ImageContent";
import Seasons from "../Seasons/Seasons";
import Audio from "../Audio/Audio";
import i18n from '../Language/i18n';  
import { Suspense } from "react";
import '../FullPage/Fullpage.scss';
import localContext from "../Language/localContext";


function Loading() {
    return(
        <>Loading...</>
    )
}

function FullPageScroll() {

    const [locale, setLocale] = useState(i18n.language);

    i18n.on('languageChanged', (lng)=> setLocale(i18n.language));

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
    } 

    return(
        <>  
        <section id='Home'>
            <Navbar/>
            <localContext.Provider value={{locale, setLocale}}>   
                <Suspense fallback={<Loading/>}> 
                    <div className="localization">
                        <select className="localization__select" value={locale} onChange={handleChange}>
                            <option className="localization__option" value="en">En</option>
                            <option className="localization__option" value="ua">Ua</option>
                            <option className="localization__option" value="ru">Ru</option>
                        </select>
                    </div>
                    <Hero/>
                </Suspense>
            </localContext.Provider> 
        </section>
        <section id='Dream' style={{ background: 'black'}}>
            <ImageContent/>
        </section>
        <section id="Video" style={{ background: 'black'}}>
            <Seasons/>
        </section>
        <section id='Audio' style={{ background: 'black'}}>
            <Audio/>
        </section>
        

       
    </>
    )

}

export default FullPageScroll;