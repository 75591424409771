import { useTranslation } from "react-i18next";
import "../AudioCard/AudioText.scss";

function AudioText() {
    const {t} = useTranslation();

    return(
        <div className="audioText">
            <h2>               
                {t('myAudioText')}
            </h2>
        </div>
    )
}

export default AudioText;