import { useState } from "react";
import { useTranslation } from 'react-i18next';
import './ForgotPassword.scss'

import axios from "axios";


const LOGIN_RESET_URL = 'https://admin.ruslanverin.com:8443/api/auth/change-password-request/'


function ForgotPassword({closeForgotModal}) {
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const {t} = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const requestData = {
            email: email,
          };
    
          const response = await axios.post(LOGIN_RESET_URL, requestData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          console.log(response.data);
          setResponseMessage(response.data.message)
          closeForgotModal();

        } catch (error) {
            setResponseMessage('User with this email does not exist.')
        }
      }

    return (
        <>
            <section className="forgot">
                <h1 className="forgot__title">Enter Email</h1>
                <span className="forgot__errormsg">{responseMessage}</span>
                <form className="forgot__form" onSubmit={handleSubmit}>
                    <input 
                        className="forgot__form-input" 
                        type="email" 
                        placeholder="Email"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                    />
                    <span className="forgot__form-info">{t('forgotText')}</span>
                    <div className="forgot__form-btn-container">
                        <button className="forgot__form-btn">Reset Password</button>
                    </div>
                </form>
            </section>
        </>
    )
}

export default ForgotPassword;