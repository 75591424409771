import React from "react";
import { useState } from "react";
import Links from "../Sidebar/Links";
import { motion } from 'framer-motion';
import ToogleButton from "../Sidebar/ToogleButton";
import "../Sidebar/Sidebar.scss";

function Sidebar() {

    const [open, setOpen] = useState(false);

    const variants = {
        open: {
            clipPath: "circle(1200px at 0px 0px)",
            transition: {
                delay: 0.5,
                type:"spring",
                stiffness: 20,


            } 
        },
        closed: {
            clipPath: "circle(0px at 0px 0px)", 
            transition: {
                delay: 0.5,  
                type:"spring",
                stiffness: 400,
                damping: 40,

            },
        },
    };

    const handleLinkClick = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        setOpen(false); 
    };

    return( 
        <motion.div className="sidebar" animate={open ? "open" : "closed"}>
        <motion.div className="bg" variants={variants}>
            <Links handleLinkClick={handleLinkClick}  />
        </motion.div>
        <ToogleButton setOpen={setOpen} />
    </motion.div>
    )
}

export default Sidebar; 