import React from "react";
import './AudioCard.scss';

function AudioCard({ data }) {
    return (
        <div className="audio-card">
            <a href={data.link} target="_blank" rel="noopener noreferrer">
                <img src={data.image} alt={data.name} />
            </a>
            <div className="audio-card-info">
                <h2 className="audio-card-title">{data.name}</h2>
                <p className="audio-card-subtitle">{data.description}</p>
            </div>
        </div>
    )
}

export default AudioCard;