import React, { useRef, useState, useEffect, useContext } from "react";
import AuthContext from '../context/AuthProvider';

import axios from "axios";
import './Login.scss';

const AUTH_URL = 'https://admin.ruslanverin.com:8443/auth/register/'

function Login({closeModal, goToForgotPassword}) {
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState("");


    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestData = {
            username,
            email: username,
            password,
        };
        
        try {
            const response = await axios.post(AUTH_URL, 
                JSON.stringify(requestData),
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true 
                }
            );
            
            const token = response?.data?.access_token;
            localStorage.setItem('token', token);

            setAuth({ token });
            setUsername('');
            setPassword('');
            setSuccess(true);

            closeModal();
            
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg ('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg ('Authorization error');
            } else {
                setErrMsg ('Login Failed');
            }
            errRef.current.focus();
        }
    }

return(
    <>
        <section className="login">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <h1 className="login__title">Fill out the form</h1>
            <form className="login__form" onSubmit={handleSubmit}>
                <input 
                    className="login__form-input" 
                    type="email" 
                    ref={userRef}
                    placeholder="Email"
                    autoComplete="off"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                />
                <input 
                    className="login__form-input" 
                    type="password" 
                    placeholder="Password" 
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                />
                <div className="login__forgot">
                    <a onClick={goToForgotPassword}>Forgot your password?</a>
                </div>
                <div className="login__form-btn-container">
                    <button className="login__form-btn">Continue</button>
                </div>
            </form>
        </section>    
    </>
)

}



export default Login;