import React from 'react';
import FullPageScroll from './FullPage/FullPage';
import Profile from './Profile/Profile';
import Error from './404/Error';



import { BrowserRouter as Router, Route, Routes,} from 'react-router-dom';
import './App.scss';


function App() {
  return (
    <Router>
      <Routes>
        <Route exect path="/" element={<FullPageScroll/>} />
        <Route exect path="/profile" element={<Profile/>} />
        <Route path="*" element={<Error/>} />

      </Routes>
    </Router>
  );
}

export default App;
