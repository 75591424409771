import axios from "axios";

import { useState, useEffect } from "react";


axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); 
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

function useHistory() {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('https://admin.ruslanverin.com:8443/orders-history/')
        .then(res => {
            setData(res.data);        
        }).catch(error => {
            console.log(error);
        });
    }, []);

    return data; 
}

export default useHistory;