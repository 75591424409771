import React, {useRef, useContext} from 'react';
import './VideoCard.scss'; 
import { LuTimerReset } from 'react-icons/lu';
import { LuTimerOff } from 'react-icons/lu';
import { BsFillGeoAltFill } from "react-icons/bs";
import { useState } from 'react';
import Login from '../../Login/Login';
import Register from '../../components/Register/Register'
import ReactModal from 'react-modal';
import {AiOutlineClose} from 'react-icons/ai';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import axios from "axios";

import AuthContext from '../../context/AuthProvider';

const BUY_REQUEST_URL = 'https://admin.ruslanverin.com:8443/api/content/buy-season/';
const AUTH_URL = 'https://admin.ruslanverin.com:8443/auth/register/'

function VideoCard({ data }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [forgotModal, setForgotModal] = useState(false);
    const [btnData, setBtnData] = useState('');
    const [btnSignature, setBtnSignature] = useState('');
    const [value, setValue] = useState('');
    const [signature, setSignature] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const userRef = useRef();
    const errRef = useRef();
    const { setAuth } = useContext(AuthContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestData = {
            username,
            email: username,
            password,
        };
        
        try {
            const response = await axios.post(AUTH_URL, 
                JSON.stringify(requestData),
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true 
                }
            );
            
            const token = response?.data?.access_token;
            localStorage.setItem('token', token);

            setAuth({ token });
            setUsername('');
            setPassword('');
            setSuccess(true);
            buyAfterLogin(token);
            
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg ('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg ('Authorization error');
            } else {
                setErrMsg ('Login Failed');
            }
            errRef.current.focus();
        }
    }
    

    axios.interceptors.request.use((config) => {
        const token = localStorage.getItem('token'); 
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });

    const handleBuyClick = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            setIsModalOpen(true);
        
        } else {
            setIsOpen(true);
            try {
                const requestData = {
                    purchased_seasons: data.id,
                };

          
                const response = await axios.post(BUY_REQUEST_URL, requestData, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });

                const dataMatch = response.data.html.match(/name="data" value="([^"]*)"/);
                const signatureMatch = response.data.html.match(/name="signature" value="([^"]*)"/);

                const dataValue = dataMatch ? dataMatch[1] : null;
                const signatureValue = signatureMatch ? signatureMatch[1] : null;

                setBtnData(dataValue)
                setBtnSignature(signatureValue)
      
              } catch (error) {
                console.log(error)
              }
            }
        
    };
    const buyAfterLogin = async (token) => {
        try {
            const requestData = {
                purchased_seasons: data.id,
            };
   
            const response = await axios.post(BUY_REQUEST_URL, requestData, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
          

            const dataMatch = response.data.html.match(/name="data" value="([^"]*)"/);
            const signatureMatch = response.data.html.match(/name="signature" value="([^"]*)"/);

            const dataValue = dataMatch ? dataMatch[1] : null;
            const signatureValue = signatureMatch ? signatureMatch[1] : null;
            setValue(dataValue)
            setSignature(signatureValue)

            const liqpayURL = `https://www.liqpay.ua/api/3/checkout/?data=${encodeURIComponent(dataValue)}&signature=${encodeURIComponent(signatureValue)}`;
            window.location.href = liqpayURL;
          } catch (error) {
            console.log(error)
          }
    }

    const handleWatchClick = () => {
        if (data.link) {
            window.open(data.link, '_blank');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeAfterLogin = (e) => {
        setIsModalOpen(false);
        window.location.reload();
    };

    const goToForgotPassword = () => {
        setIsModalOpen(false);
        setForgotModal(true);
    }

    const closeForgot = () => {
        setForgotModal(false)
    }

    const [isShow, setIsShow] = useState(false);

    const formattedCoordinates = encodeURIComponent(data.geo);
    const googleMapsLink = `https://www.google.com/maps/place/${formattedCoordinates}`;
    
    const buttonStyle = {
        background: '#fff',
        cursor: 'pointer',
        border: 'none',
        display: 'inline-block',
        textAlign: 'center',
        padding: '5px 24px',
        color: '#fff',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
        fontSize: '16px',
        lineHeight: '1.75',
        fontWeight: '600',
        fontFamily: 'Open Sans, sans-serif',
        borderRadius: '8px',
        background: '#77CC5D',
      };

    return (
        <div className="video-card">
            <div className="video-card-info">
                <img src={data.image} alt={data.name} />
                <div>
                    <h2 className='title'>{data.name}</h2>
                </div>

                <div>
                    <div className='buttons'>
                        <div className='title-price'>
                            {data.price === 0 ? (
                                <p className='price'>Free</p> 

                            ):(
                                <p className='price'>$ {data.price}</p>
                            )}                        
                        </div>
                        <button className='btn-more' type="button" onClick={() => setIsShow(true)}>More</button>
                        
                        {isShow && (
                            <div className='more'>
                                <div className='desc'>
                                    <p className='description'>Description</p>                            
                                </div>

                                <p className='desc-text'>{data.description}</p>

                                <div className='geo-block'>
                                    <div className='timers'>
                                        <p className='sdate'> <LuTimerReset style={{width: '30px', height: '30px'}}/> {data.start_date}</p>
                                        <p className='edate'><LuTimerOff style={{width: '30px', height: '30px'}}/> {data.end_date}</p>
                                    </div>
                                    <div className='geoBl'>
                                        <BsFillGeoAltFill style={{width: '30px', height: '30px', opacity: .5}}/>
                                        <a className='geo' href={googleMapsLink} target='blank'> {data.geo}</a>
                                    </div>
                                </div>

                                <div>
                                    {data.link || data.price === 0 ? (
                                        <button onClick={handleWatchClick} className='btn-buy'>
                                            Watch
                                        </button>
                                    ) : (
                                        <button onClick={handleBuyClick} className='btn-buy'>
                                            Buy
                                        </button>
                                    )}
                         
                                <button className='btn-close' type="button" onClick={() => setIsShow(false)}>Close</button>
                                </div>
                            </div>
                        )}
                 
                        {data.link || data.price === 0 ? (
                            <button onClick={handleWatchClick} className='btn-buy'>
                                Watch
                            </button>
                        ) : (
                            <button onClick={handleBuyClick} className='btn-buy'>
                                Buy
                            </button>
                        )}
                
                </div>
                
            {isOpen && (
                <div className='liqpay-modal'>
                    <AiOutlineClose className='liqpay-modal-close' onClick={() => setIsOpen(false)}/>
                        <form  method="POST" action="https://www.liqpay.ua/api/3/checkout/" accept-charset="utf-8">
                            <input type="hidden" name="data" value={btnData} />
                            <input type="hidden" name="signature" value={btnSignature} />
                            <script type="text/javascript" src="https://static.liqpay.ua/libjs/sdk_button.js"></script>
                            <sdk-button label="Pay" background="#77CC5D" style={{display: 'block'}} onclick="submit()">
                                <button class="sdk" style={buttonStyle}>
                                    <img src="https://static.liqpay.ua/buttons/logo-white.svg" name="btn_text" style={{verticalAlign: 'middle'}}/>
                                    <span style={{ verticalAlign: 'middle', marginLeft: '8px', textTransform: 'uppercase' }}>Pay</span>
                                </button>
                            </sdk-button>
                        </form>
                </div>
            )}

            {isModalOpen && (
                <div className="customLoginModal">
                    <p style={{margin:0}} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1 className="customLoginTitle">Fill out the form</h1>
                    <form className="customLoginForm" onSubmit={handleSubmit}>
                        <input 
                            className="login__form-input" 
                            type="email" 
                            ref={userRef}
                            placeholder="Email"
                            autoComplete="off"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            required
                        />
                        <input 
                            className="login__form-input" 
                            type="password" 
                            placeholder="Password" 
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                        />
                        <div className="login__forgot">
                            <a onClick={goToForgotPassword}>Forgot your password?</a>
                        </div>
                        <sdk-button label="Pay" background="#77CC5D" style={{display: 'block'}}>
                                <button class="sdk" style={buttonStyle}>
                                    <img src="https://static.liqpay.ua/buttons/logo-white.svg" name="btn_text" style={{verticalAlign: 'middle'}}/>
                                    <span style={{ verticalAlign: 'middle', marginLeft: '8px', textTransform: 'uppercase' }}>Pay</span>
                                </button>
                            </sdk-button>
                    </form>
                    <button style={{cursor:'pointer'}} className='closeModal-btn ' onClick={() => setIsModalOpen(false)}><AiOutlineClose/></button>
                </div> 
            )}

                    <ReactModal
                    isOpen={forgotModal}
                    onRequestClose={closeForgot}
                    contentLabel="Forgot Passworg Modal"
                    className="login-modal"
                    >
                        <button onClick={closeForgot} className='closeModal-btn'><AiOutlineClose className='close-icon'/></button>
                        <ForgotPassword closeForgotModal={closeForgot}/>
                    </ReactModal>
                </div>
            </div>
        </div>
    );
}

export default VideoCard;