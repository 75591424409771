import React, { useState } from "react";
import './Register.scss';
import axios from "axios";
import { useTranslation } from 'react-i18next';

const REGISTER_URL = 'https://admin.ruslanverin.com:8443/auth/register/'

function Register({closeRegister}) {
    const [first_name, setFirsName] = useState('');
    const [last_name, setSecondName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const {t} = useTranslation();

    const [pwdErr, setPwdErr] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();

        if (password !== confPassword) {
            setPwdErr("Passwords do not match");
            return false;
          } else {
            try {
                const response = axios.post(REGISTER_URL, 
                    JSON.stringify({first_name,
                                    last_name,
                                    username,
                                    email,
                                    password}),
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true 
                    }
                );
                
                closeRegister();

            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <div className="register">
            <p className={pwdErr ? "errmsg" : "offscreen"} aria-live="assertive">{pwdErr}</p>
            <h1 className="register__title">
                Register
            </h1>
            <form className="register__form" onSubmit={handleSubmit}>
                <input 
                    className="register__form-input" 
                    type="text" 
                    placeholder="First Name"
                    onChange={(e) => setFirsName(e.target.value)}
                    value={first_name}
                    required 
                />
                <input 
                    className="register__form-input" 
                    type="text" 
                    placeholder="Last Name"
                    onChange={(e) => setSecondName(e.target.value)}
                    value={last_name}
                    required
                />
                <input 
                    className="register__form-input" 
                    type="login" 
                    placeholder="Username" 
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                />
                <input 
                    className="register__form-input"
                    type="email" 
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                />
                <input 
                    className="register__form-input" 
                    type="password" 
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                />
                <input 
                    className="register__form-input" 
                    type="password" 
                    placeholder="Confirm Password" 
                    onChange={(e) => setConfPassword(e.target.value)}
                    value={confPassword}
                    required
                />
                <span className="register__form-info">{t('registerText')}</span>
                <button className="register__form-submit">
                    Submit
                </button>
            </form>
        </div>
    );
}

export default Register;