import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useInView } from 'framer-motion';
import { useState, useRef } from 'react';


import rus from '../img/ruslan.jpg';
import "./Hero.scss";


const textVariants = {
    initial: {
        x: -500,
        opacity: 0,
    },
    animate:{
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: .1,
        },
    },
};

const modalVariants = {
    initial:{ scale: 0, y:-1000, opacity: 0 },
    animate:{  scale: 1, y:0, opacity: 1, transition: ".9s" },
    transition:{
    type: "spring",
    stiffness: 260,
    damping: 20,
    duration: 3,
    staggerChildren: .5,
  },
};


const sliderVariants = {
    initial: {
        x: 0,
    },
    animate:{
        x: "-145%",
        transition: {
            duration: 20,
            repeat: Infinity,
            repeatType:"mirror"
        },
    },
};

const imageVariants = {
    initial: {
        opacity: 0,
    },
    animate:{
        opacity:1,
        transition: {
            duration: 1,
            staggerChildren: .1,
            delay: 1,
        }
        
    },
}


function Hero() {

    const [isShow, setIsShow] = useState(false);
    const ref = useRef()
    const isInView = useInView(ref, {margin:'-100px'})

    const {t} = useTranslation();
    

    return(
        <div className="hero">
            <div className="wrapper"> 
                <motion.div 
                className="textContainer"
                variants={textVariants}
                initial="initial"
                animate="animate"
                >
                    <motion.h2 variants={textVariants}>{t('name')}</motion.h2>
                    <motion.h1 variants={textVariants} style={{fontSize: '63px'}}>
                        {t('subtitle')}
                    </motion.h1>
                    <motion.div className="buttons" variants={textVariants}>
                        <motion.button className='btn-modal' onClick={() => setIsShow(true)}  variants={textVariants}>{t('historuBtnOpen')}</motion.button>
                            {isShow && (
                        <motion.div
                        className='bg-modal'
                        variants={modalVariants}
                        >
                            <h2>{t('historyTitle')}</h2>
                            <p className='modal-text'>{t('historyText')}</p>
                            <button className='close-modal' type="button" onClick={() => setIsShow(false)}>{t('historyBtnClose')}</button>
                        </motion.div>
                        )}
                    </motion.div>

                </motion.div>
            </div>
            <motion.div 
            className="slidingTextContainer" 
            variants={sliderVariants}
            initial="initial"
            animate="animate"
            >
                Travel
            </motion.div>
            <div className="imageContainer">
                <motion.img 
                src={rus} 
                alt="gmane"
                variants={imageVariants}
                initial="initial"
                ref={ref}
                animate={isInView && "animate"}
                />
            </div>
        </div>
    )
}

export default Hero;