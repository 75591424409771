import React from "react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { useTranslation } from 'react-i18next';

import '../Seasons/Seasons.scss';
import VideoCard from "../components/VideoCard/VideoCard";
import useSeasons from "../hooks/useSeasons";
import VideoText from "../components/VideoCard/VideoText";

const titleTextVariants = {
    initial: {
        x: -500,
        opacity: 0,

    },
    animate:{
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: .1,
        },
    },
};

const textVariants = {
    initial: {
        x: -500,
        opacity: 0,

    },
    animate:{
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: .1,
        },
    },
};


const cardsVariants = {
    initial: {
        opacity: .1,
        
        y: 42
    },
    animate:{
        opacity:1,
        x: 0,
        transition: {
            duration: 2,
            staggerChildren: .1,
            delay: 1,
        }
        
    },
}


function Seasons() {

    const seasonsData = useSeasons()
    const ref = useRef()
    const isInView = useInView(ref, {margin:'-100px'})
    const {t} = useTranslation();

    return(
        <div className="seasons">
            <div className="seasons__title">
                <h1
                className="season__title-text"
                variants={titleTextVariants}
                initial="initial"
                ref={ref}
                animate={isInView && "animate"}
                style={{color:'rebeccapurple'}}
                >
                    {t('videoTitle')}
                </h1>
            </div>
            <motion.div
            className="seasons__text"
            variants={textVariants}
            initial="initial"
            ref={ref}
            animate={isInView && "animate"}
            >
                {/* <VideoText/> */}
            </motion.div>
            <motion.div
            className="seasons__content"
            variants={cardsVariants}
            initial="initial"
            ref={ref}
            animate={isInView && "animate"}
            >
                <div className="seasons__content-cards">
                    <div className="seasons__content-card"></div>
                    {seasonsData.map(item =>(
                        <VideoCard data={item}/>
                    ))}
                    </div>
            </motion.div>
        </div>
    )
}

export default Seasons;


